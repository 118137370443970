<template>
  <div class="thedoncu">
    <div class="header d-flex justify-content-between">
      <span class="font-weight-bold">{{ order.code }} - {{ order.service_code }} - {{ order.receivedfromsource }}</span>
      <span>{{ order.tdv.code }} - {{ moment(order.created_at).format('HH:mm D-M-Y') }}</span>
    </div>

    <div class="body">
      <b-badge href="#" variant="danger">{{ order.later_pickup != '00:00' ? 'Hẹn giờ lấy:  '+ order.later_pickup: '' }}</b-badge>
      <b-badge v-if="order.later_pickup != '00:00' && order.later_pickup_value == 0" variant="warning">Tự động chuyển</b-badge>
      <pre>{{ order.content }} <span className="phone"> (0{{ order.user.phone }})</span></pre>
    </div>

    <div v-if="order.lat_from" class="d-flex justify-content-between align-items-baseline">
      <div v-if="order.lat_from"><b>Bắt đầu:</b> <span>{{order.address_from}}</span></div>
      <span class="badge badge-danger align-self-end mr-3">
        Đơn có vị trí
      </span>
    </div>

    <div v-if="order.lat_to" class="d-flex justify-content-between align-items-baseline">
      <div v-if="order.lat_to"><b>Kết thúc:</b> <span>{{order.address_to}}</span></div>
    </div>

    <div class="footer d-flex justify-content-between align-items-baseline">
      <div>
        <span class="font-weight-bold">»» {{ order.fee }} k </span> <b-badge v-if="order.is_urgent == 1" variant="danger">ĐƠN GẤP</b-badge>
      </div>
      <div class="d-flex">
        <span v-if="order.shipper">Shipper: {{ order.shipper.code }}</span>
      </div>
    </div>

    <div class="d-flex mt-1 justify-content-between align-items-baseline">
      <b-button class="mr-1" size="sm" @click="taoLai">
        <b-icon icon="backspace"/>
        TẠO LẠI
      </b-button>
      <b-badge variant="primary">{{order.stateorder.name}}</b-badge>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale('vi')

export default {
  name: 'thedon',
  props: ['order', 'taoLaiDon'],
  data: function () {
    return {
      moment
    }
  },
  methods: {
    taoLai() {
      this.taoLaiDon(this.order);
    },

    copyMaDon() {
      this.$clipboard(this.order.code)
    },
    copyNoiDung() {
      this.$clipboard(this.order.content)
    }
  },
  watch: {

  }
}
</script>

<style lang="scss" >
.thedoncu {
  height: auto;
  font-size: 15px;
  border: 1px solid rgba(0, 0, 0, .225);
  border-radius: 5px;
  margin: 3px;
  padding: 7px;

  .header {
    line-height: 100%;
  }

  .body {
    margin: 5px 10px 5px 15px;
  }

  .v-context {
    li a {
      display: flex;
      align-items: center;

      &:hover {
        background-color: darkgrey;
      }
    }
  }
}
</style>
